//Font Sizes
:root {
  --font-xs: 10px;
  --font-small: 12px;
  --font-medium: 14px;
  --font-large: 16px;
  --font-ls-large: 24px;
  --font-md-large: 32px;
  --font-lx-large: 50px;

  --font-weight-400: 400;
  --font-weight-600: 600;
  --font-weight-700: 700;
}

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@trimble-oss/modus-icons@1/dist/modus-solid/fonts/modus-icons.css');

@import "ag-grid-community/styles/ag-grid.css";

* {

  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.is-working {
  z-index: 100001;
}


.title {
  font-size: var(--font-ls-large);
  font-weight: 400;
}

.subtitle-bold {
  font-size: var(--font-large);
  font-weight: 700;
  padding-top: 10px;
}

.heading-gray {
  font-size: var(--font-small);
  padding-top: 10px;
  font-weight: var(--font-weight-400);
  color: var(--modus-gray-5);
}

.heading-black-bold {
  font-size: var(--font-medium);
  font-weight: 700;
}

.heading-black {
  font-size: var(--font-medium);
  font-weight: var(--font-weight-400);
}

.container {
  display: flex;
  flex-direction: row;
  width: calc(100% + 360px);
  min-height: calc(100vh - 56px - 3rem); // viewport height - header height - top & bottom margin - footer height

  .main-view-drawer {
    height: calc(100vh - 105px);
    overflow-y: scroll;
    width: calc(100% - 360px);

    transition: 0.3s ease;
    transition-property: width;

    &.pushed-left {
      width: calc(100% - 720px);
    }

    .main-view-container {
      margin: 2rem 1.2rem 0 2rem;
    }
  }
}


.svg-icon-delete {
  width: 26px;
  height: 22px;
  top: 5px;position: absolute;
  left: 4px;
}

.main-view-add-icon-svg{
    height: 1.5rem;
}

.div-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.div-buttons-right {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

}

/*Layout Divs paarent with childs divs*/
.flex{
  display: flex;
}

/*
Flex-Wrap-Speace-between
    *****************
    * []         [] *
    *               *
    *               *
    *****************
*/
.flex-row-Space-between-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: -webkit-fill-available;
  width: -moz-available;
}

/*
> flex-justify-content-align-items:

    ******************
    *                *
    *       []       *
    *                *
    ******************
*/
.flex-justify-content-align-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-size {
  height: calc(100vh - 140px);
  position: relative;
  width: 7rem;
}

.notifications-menu {
  right: -11rem !important;
}

.breadcrumb ul {
  width: 100% !important;
}

/*Wrap text*/
.breakText {
  white-space: pre-wrap;
  word-break: normal;
}


.m-1 {
  margin: 8px;
}

.m-2 {
  margin: 16px;
}

.d-none {
  display: none !important;
}

.svg-icon {
  position: absolute;
  top: 8px;
  left: 5px;
}






/*Form Panel*/

.div-panel {
  min-width: 200px;
  gap: 6vh;
  box-shadow: 0px 0px 2px rgba(54, 53, 69, 0.3);
  border-radius: 3px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.div-inputs {
  // max-width: 50%;
  gap: 10px;
  align-items: center;
}

.div-alert {
  padding-top: 20px;
}

.pt-1 {
  padding-top: 1em;
}

.pt-05 {
  padding-top: .5em;
}


.pl-1{
  padding-left: 1em;
}

.p-1 {
  padding: 1em;
}

.pb-1 {
  padding-bottom: 1em;
}


.pl-13 {
  padding-left: 1.3em;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-4px{
  margin-top: -4px;
}


.w-100{
  width: 100%;
}

.w-50{
  width: 50%;
}

.div-panel,
.div-inputs,
.div-buttons,
.buttons-space {
  display: flex;
  flex-wrap: wrap;
}

.div-panel-grid-wraping-border {
  box-shadow: 0px 0px 2px rgba(54, 53, 69, 0.3);
  border-radius: 3px;
  padding: 20px;
}

.div-panel-grid-wraping {
  min-width: 200px;



  & .div-input-form-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(200px, 20vw), 1fr));
    grid-gap: 10px;

    & .full-width {
      grid-column: 1/-1;
    }

    & .half-width {
      width: max(50%, min(100%, 600px));
      display: block;
    }

  }

  & .div-input-form-3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(200px, 20vw), 1fr));
    grid-gap: 10px;

    & .full-width {
      grid-column: 1/-1;
    }

    & .half-width {
      width: max(50%, min(100%, 600px));
      display: block;
    }

  }
}




@media only screen and (max-width: 600px) {
  .notifications-size {
    height: calc(100vh - 140px);
    position: relative;
    width: 222px;
    height: 100vh;
  }
}

.font-xs {
  font-size: var(--font-xs);
}

.font-small {
  font-size: var(--font-small);
}

.font-medium {
  font-size: var(--font-medium);
}



.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}


.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;

}

.ccx-dialog-overlay__backdrop{
  background-color: var(--modus-backdrop-bg);
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
  opacity: 0;
}

.cdk-dialog-container {
  background-color: var(--modus-white);
  border-radius: 0.125rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--modus-white);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .125rem;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem calc(1rem - 1px);
  border-bottom: 1px solid #e0e1e9;
  border-top-left-radius: calc(.125rem - 1px);
  border-top-right-radius: calc(.125rem - 1px);
}

.modal-header button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.modal-body {
  position: relative;
  flex: auto;
  padding: 1rem;
}

.modal-footer {
  padding: calc(1rem - 1px)1rem 1rem;
}





// Toastr

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 10%;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-palette-trimble-gray, #252a2e);

  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 22px 15px 15px 50px;
  padding-bottom: 5px;
  padding-top: 10px;
  width: 480px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;

  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-shadow: rgba(36, 35, 45, 0.3) 0px 0px 8px;

}

.toast-container .ngx-toastr:hover {
  // box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1pbmZvIiBoZWlnaHQ9IjE4IiB3aWR0aD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDIxQzE2Ljk2OCAyMSAyMSAxNi45NjggMjEgMTJDMjEgNy4wMzIgMTYuOTY4IDMgMTIgM0M3LjAzMiAzIDMgNy4wMzIgMyAxMkMzIDE2Ljk2OCA3LjAzMiAyMSAxMiAyMVpNMTEgN0gxM1Y5SDExVjdaTTExIDExSDEzTDEzIDE3SDExTDExIDExWiIgZmlsbD0iIzBENkFBOCI+PC9wYXRoPjxtYXNrIGlkPSJtYXNrMCIgbWFzay10eXBlPSJhbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMyIgeT0iMyIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDIxQzE2Ljk2OCAyMSAyMSAxNi45NjggMjEgMTJDMjEgNy4wMzIgMTYuOTY4IDMgMTIgM0M3LjAzMiAzIDMgNy4wMzIgMyAxMkMzIDE2Ljk2OCA3LjAzMiAyMSAxMiAyMVpNMTEgN0gxM1Y5SDExVjdaTTExIDExSDEzTDEzIDE3SDExTDExIDExWiIgZmlsbD0id2hpdGUiPjwvcGF0aD48L21hc2s+PGcgbWFzaz0idXJsKCNtYXNrMCkiPjwvZz48L3N2Zz4=");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi13YXJuaW5nIiBoZWlnaHQ9IjE4IiB3aWR0aD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuNzU0NjggMTkuNzc5QzQuMTEzOTEgMTkuNzc5IDMuNTQzMTIgMTkuNDUwNCAzLjIyODIzIDE4LjkwMDJDMi45MjAxOSAxOC4zNjE3IDIuOTI0MzEgMTcuNzIwOSAzLjIzODUyIDE3LjE4NjVMMTAuNDgzOCA0Ljg2MTY3QzEwLjgwMDggNC4zMjI0NCAxMS4zNjc1IDQgMTIgNEMxMi42MzI1IDQgMTMuMTk5OSA0LjMyMjQ0IDEzLjUxNjIgNC44NjE2N0wyMC43NjE1IDE3LjE4NjVDMjEuMDc1NyAxNy43MjA5IDIxLjA3OTggMTguMzYxNyAyMC43NzE4IDE4LjkwMDJDMjAuNDU2OSAxOS40NTA0IDE5Ljg4NjEgMTkuNzc5IDE5LjI0NTMgMTkuNzc5SDQuNzU0NjhaTTEyLjAwMDEgMTcuMDk4OUMxMS4zMjY0IDE3LjA5ODkgMTAuNzgyMyAxNi41NDMyIDEwLjc4MjMgMTUuODgxMUMxMC43ODIzIDE1LjIxOTEgMTEuMzI2NCAxNC42NjM0IDEyLjAwMDEgMTQuNjYzNEMxMi42NzM4IDE0LjY2MzQgMTMuMjE3OCAxNS4yMTkxIDEzLjIxNzggMTUuODgxMUMxMy4yMTc4IDE2LjU0MzIgMTIuNjczOCAxNy4wOTg5IDEyLjAwMDEgMTcuMDk4OVpNMTEuMDc4IDEzLjY3MDZIMTIuOTIyMUwxMy4yMjk1IDguOTYyOTJIMTAuNzcwN0wxMS4wNzggMTMuNjcwNloiIGZpbGw9IiNDODE5MjIiPjwvcGF0aD48L3N2Zz4=");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1jaGVjay1jaXJjbGUiIGhlaWdodD0iMTgiIHdpZHRoPSIxOCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMiA0QzcuNTg0IDQgNCA3LjU4NCA0IDEyQzQgMTYuNDE2IDcuNTg0IDIwIDEyIDIwQzE2LjQxNiAyMCAyMCAxNi40MTYgMjAgMTJDMjAgNy41ODQgMTYuNDE2IDQgMTIgNFpNMTAuNCAxNkw2LjQgMTJMNy41MjggMTAuODcyTDEwLjQgMTMuNzM2TDE2LjQ3MiA3LjY2NEwxNy42IDguOEwxMC40IDE2WiIgZmlsbD0iIzVFOTMzMSI+PC9wYXRoPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbi1lcnJvciIgaGVpZ2h0PSIxOCIgd2lkdGg9IjE4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zIDEyQzMgNy4wMzIgNy4wMzIgMyAxMiAzQzE2Ljk2OCAzIDIxIDcuMDMyIDIxIDEyQzIxIDE2Ljk2OCAxNi45NjggMjEgMTIgMjFDNy4wMzIgMjEgMyAxNi45NjggMyAxMlpNMTIuOTIyMSAxMi42NzA2SDExLjA3OEwxMC43NzA3IDcuOTYyOTJIMTMuMjI5NUwxMi45MjIxIDEyLjY3MDZaTTEyLjAwMDEgMTYuMDk4OUMxMS4zMjY0IDE2LjA5ODkgMTAuNzgyMyAxNS41NDMyIDEwLjc4MjMgMTQuODgxMUMxMC43ODIzIDE0LjIxOTEgMTEuMzI2NCAxMy42NjM0IDEyLjAwMDEgMTMuNjYzNEMxMi42NzM4IDEzLjY2MzQgMTMuMjE3OCAxNC4yMTkxIDEzLjIxNzggMTQuODgxMUMxMy4yMTc4IDE1LjU0MzIgMTIuNjczOCAxNi4wOTg5IDEyLjAwMDEgMTYuMDk4OVoiIGZpbGw9IiNGRkJFMDAiPjwvcGF0aD48bWFzayBpZD0ibWFzazAiIG1hc2stdHlwZT0iYWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjMiIHk9IjMiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zIDEyQzMgNy4wMzIgNy4wMzIgMyAxMiAzQzE2Ljk2OCAzIDIxIDcuMDMyIDIxIDEyQzIxIDE2Ljk2OCAxNi45NjggMjEgMTIgMjFDNy4wMzIgMjEgMyAxNi45NjggMyAxMlpNMTIuOTIyMSAxMi42NzA2SDExLjA3OEwxMC43NzA3IDcuOTYyOTJIMTMuMjI5NUwxMi45MjIxIDEyLjY3MDZaTTEyLjAwMDEgMTYuMDk4OUMxMS4zMjY0IDE2LjA5ODkgMTAuNzgyMyAxNS41NDMyIDEwLjc4MjMgMTQuODgxMUMxMC43ODIzIDE0LjIxOTEgMTEuMzI2NCAxMy42NjM0IDEyLjAwMDEgMTMuNjYzNEMxMi42NzM4IDEzLjY2MzQgMTMuMjE3OCAxNC4yMTkxIDEzLjIxNzggMTQuODgxMUMxMy4yMTc4IDE1LjU0MzIgMTIuNjczOCAxNi4wOTg5IDEyLjAwMDEgMTYuMDk4OVoiIGZpbGw9IndoaXRlIj48L3BhdGg+PC9tYXNrPjxnIG1hc2s9InVybCgjbWFzazApIj48L2c+PC9zdmc+");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: var(--modus-green-pale, #e0eccf);
  border-color: var(--modus-green-dark, #006638);
  color: var(--primary-palette-trimble-gray, #252a2e);
}

.toast-error {
  background-color: var(--modus-toast-danger-bg, #fbd4d7);
  border-color: var(--modus-toast-danger-border-color, #ab1f26);
  color: var(--modus-toast-danger-color, var(--modus-toast-color, #252a2e));
}

.toast-info {
  background-color: var(--modus-toast-primary-bg, #dcedf9);
  border-color: var(--modus-toast-primary-border-color, #0063a3);
  color: var(--modus-toast-primary-color, #0063a3);
}

.toast-warning {
  background-color: var(--modus-toast-warning-bg, #fff2cc);
  border-color: var(--modus-toast-warning-border-color, #e49325);
  color: var(--modus-toast-warning-color, var(--modus-toast-color, #252a2e));
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}


// PowerBI Report classes

.powerBi-report__container__full {
  display: block;
  height: calc(100vh - 9rem); //  = viewport height - header height - footer height - top & bottom margin
}

iframe {
  border: none;
}

.empty-text-gray {
  background-color: var(--modus-gray-light);
  padding: .5em .5em .5em 1.1em;
  margin: 1em 0;
}

// Material DatePicker


.mat-calendar-table {
  margin-top: 1rem !important;
}

.mat-calendar-body-selected {
  background-color: var(--modus-blue) !important;
}

.mat-mdc-form-field-infix {
  min-height: 1rem;
}



.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: transparent;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--modus-blue) !important;
  border-width: initial;
}

.mat-mdc-form-field-flex {
  background: var(--modus-white);
  height: 2.5rem;
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
}

.mat-datepicker-toggle-active {
  color: var(--modus-blue) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: var(--modus-white) !important;
}


.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  border-radius: 0.125rem;
  border: 1px solid var(--Neutral-Gray-0, #E0E1E9);
  background: var(--Neutral-White, #FFF);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--modus-blue) !important;
}

.mdc-text-field--filled {
  height: 2.5rem !important;
}


.mat-mdc-form-field-focus-overlay {
  background-color: rgb(255 255 255 / 87%);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 2px !important;
  height: 32px;
  width: 32px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 4px  !important;
}

.mat-mdc-form-field {
  width: 100% !important;
  font-size: 0.75rem  !important;
}

.mat-mdc-form-field-subscript-wrapper {
  box-sizing: border-box;
  height: 0;
  width: 100%;
  position: relative;
}

.ds-counter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1.5px solid #E0E1E9;
  margin-top: 1em;
}


/*grid empty content*/

.no-rows-overlay__wrapper{
  display: flex;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
}
.no-rows-overlay__content{
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 16rem;
  // height: 64rem;
  /* flex-shrink: 0; */

  gap: 0.75rem;
}


.no-rows-overlay-svg-icon{
  width: 50px;
  height: 50px;
}

.small-svg-icon{
  width: 20px;
  height: 20px;
}


.revert-color-svg{
  filter: brightness(0)invert(1)''
}

.div-inputs-section{
  padding: 1rem;
  box-shadow: 0px 0px 2px 0px rgba(54, 53, 69, 0.3);
  margin-bottom: 1rem;
}

.form-width{
 width:45vw;
}

// CDK
.cdk-overlay-container, .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000
}

.cdk-overlay-container:empty {
    display: none
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop {
    transition: visibility 1ms linear, opacity 1ms linear;
    visibility: hidden;
    opacity: 1
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
    visibility: visible
}

.cdk-overlay-backdrop-noop-animation {
    transition: none
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll
}
.d-flex{
  display: flex;
}
